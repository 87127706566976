import React from "react";
import { Button, Card, Container, Accordion, Row, Col } from 'react-bootstrap';

const SectionFAQ = ({ data }) => {
    var items
    if (!data || data.length === 0) {
        items = []
    } else {
        items = data
    };

    return (
        <Container>
            <Row>
                <Col>
                    <h2 className="h1-responsive font-weight-bold mb-5 text-center">FAQ</h2>
                </Col>
            </Row>
            <Accordion>
                {items.map((data, index) => (
                    <Card key={index}>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey={data.id}>
                                <b>{data.question}</b>
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={data.id}>
                            <Card.Body><p className="mx-md-3">{data.answer}</p></Card.Body>
                        </Accordion.Collapse>
                    </Card>
                ))}
            </Accordion>
        </Container>
    )
};

export default SectionFAQ;